import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const signingAgents = () => {
  return (
    <Layout>
      <SEO
        title="Signing New Players (via Agents)"
        description="Learn how to sign players in PES 2021 myClub by spinning agents."
      />
      <div className="container">
        <h1>Signing New Players (via Agents)</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Signing New Players (via Agents)</li>
        </ul>
        <p>
          You will start with some players, but if you want to build a
          competitive squad (or simply play with the footballers you like), you
          will eventually have to sign new players.
        </p>
        <p>
          Let's start with the basics: understanding the different type of
          players you can get, and how to use Agents.
        </p>
        <h2>Types of Players</h2>
        <p>
          There are 5 different kinds of players -
          White/Bronze/Silver/Gold/Black Ball.
        </p>
        <p>
          Ball type will depend on their OVR value at level 1. You will learn
          about levels later on.
        </p>
        <ul>
          <li>White Ball: a player who is rated up to 69.</li>
          <li>Bronze Ball: a player who is rated between 70-74.</li>
          <li>Silver Ball: a player who is rated between 75-79.</li>
          <li>Gold Ball: a player who is rated between 80-84.</li>
          <li>Black Ball: a player who is rated above 85.</li>
        </ul>
        <p>
          You may be tempted to build a Black Ball team, but keep in mind that
          those players will be more expensive when it’s time to{" "}
          <Link to="/contract-stamina-renewal/">renew all their contracts</Link>
          . A Black Ball can cost around 4k for 10 games, which is pretty much
          the GP you make after winning a game.
        </p>
        <p>
          Each player also has a cost in the team. Black Balls are naturally
          more expensive - this will force you to get a top manager if you want
          to play with all your superstars, which is more expensive not only to
          obtain but to keep in your club.{" "}
          <Link to="/signing-managers/">
            Go here to learn more about Signing Managers
          </Link>
          .
        </p>
        <p>
          But how do you get those “superstars” players? There isn’t a
          “marketplace” like in FIFA’s Ultimate Team, where you can simply
          search for “Ronaldo” and buy. You will need to rely on Agents or
          Scouts.
        </p>
        <p>
          Let’s look into Agents first. We will look into{" "}
          <Link to="/signing-new-players-scout-combinations/">Scouts</Link>{" "}
          next.
        </p>
        <h2>Using Agents</h2>
        <p>
          If you’re feeling lucky and you have some GP/coins to spend, you can
          spin an Agent. This year, there are quite some Agent options in PES.
        </p>
        <h3>Collector’s Box</h3>
        <p>
          Every week, a new box pops up, usually with 300 or 450 players you can
          get. Each spin will cost you 25.000 GP (or 250 coins) and will get you
          3 players (level 1).
        </p>
        <p>
          This is also the only Agent that can get you Legends (such as Maradona
          or Roberto Carlos).
        </p>
        <p>All these players will be level 1, Silver Ball or above.</p>
        <h3>Special Agent</h3>
        <p>
          On most weeks (including International Fixtures), you will get a
          “Players of the Week” box with 11 players that performed well in real
          life. Those can be from Clubs or from National Teams (which was a
          novelty in PES 2020).
        </p>
        <p>
          There will also be a “Featured Club” (sometimes even more than just 1)
          with a selection of players (usually 8) that are rated higher than
          their default Overall Score. Assuming it’s a decent team, most will be
          Black Balls.
        </p>
        <p>
          For both, you can only try your luck 3 times and each spin will cost
          you 100 coins. You will only get 1 player (level 1) per spin. Keep in
          mind that you can’t use GP for this.{" "}
        </p>
        <h3>Top Agent</h3>
        <p>There are 4 Top Agents you can use:</p>
        <ul>
          <li>one will get you 3 Forwards;</li>
          <li>one will get you 3 Midfielders;</li>
          <li>one will get you 3 Defenders;</li>
          <li>one will get you 3 Goalkeepers.</li>
        </ul>
        <p>
          This Agent is helpful if you want to improve a specific area in your
          team.
        </p>
        <p>All these players will be level 1, Silver Ball or above.</p>
        <h3>Agents as Rewards</h3>
        <p>
          Every Monday morning (8 AM UTC), a couple of new challenges become
          available where you may get an Agent if you win the game/cup. This
          will usually give you a Silver Ball or above, level 1.
        </p>
        <p>
          Unlike previous years, this has been a rare reward in PES 2020 so it
          could be the same case for 2021 - Season Update.
        </p>
        <p>
          From time to time, new <Link to="/legends/">legends</Link> will become
          available. On special occasions, Konami may leave a daily Agent in
          your inbox for you try your luck. You just need to login during a
          specific period, usually a whole week.
        </p>
        <p>
          For instance, you could try to spin those agents to get Batistuta, who
          would be included in a pool with other Argentinian players.
        </p>
        <p>
          As you noticed, this will randomly assign you new players.{" "}
          <Link to="/signing-new-players-scout-combinations/">
            If you're looking for a specific player, you need a set of Scouts,
            which is what we will look into next.
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default signingAgents
